import React, { useEffect, useState, useMemo } from 'react';

import { EditRounded, DoneOutlined } from '@mui/icons-material';

import {
  useSensor,
  useSensors,
  DndContext,
  DragOverlay,
  MouseSensor,
  TouchSensor,
  closestCenter,
  DragOverEvent,
  KeyboardSensor
} from '@dnd-kit/core';
import { restrictToParentElement } from '@dnd-kit/modifiers';
import { SortableContext, rectSortingStrategy } from '@dnd-kit/sortable';

import { Property } from './Property/Property';
import { StaticDndProperty } from './Property/StaticDndProperty';
import { PropertyDrillDown } from './PropertyDrillDown/PropertyDrillDown';
import { AddOrEditPropertyDialog } from './AddOrEditPropertyDialog/AddOrEditPropertyDialog';
import { OverviewNoDataAvailable } from '../OverviewNoDataAvailable/OverviewNoDataAvailable';
import { PropertiesPendingAndNACard } from './PropertiesPendingAndNACard/PropertiesPendingAndNACard';

import { StyledText } from '@/components/lib';
import { PropertyContainer } from './Property/Property.styles';
import {
  LoaderContainer,
  PropertiesLoader,
  PropertiesContainer,
  PropertiesAddCardText,
  PropertiesInnerContainer,
  PropertiesHeaderContainer
} from './Properties.styles';

import { getAppStorage } from '@/helpers/utils/localStorage';
import { usePollingIndicator } from '@/helpers/hooks/usePolling';
import { usePropertiesInfo } from '@/helpers/hooks/usePropertiesInfo';
import { handleChangeOrderOfProperties } from './properties.helpers';
import {
  EnvType,
  useListPropertiesConfigs,
  PropertyConfigSchemaOutput,
  useListPropertiesDefinitions,
  propertiesConfigsReorderApiV1PropertiesConfigReorderPut,
  useListPropertiesDataApiV1ApplicationVerionsAppVersionIdPropertiesConfigDataGet
} from '@/helpers/services/api';

import { constants } from '../overview.constants';

interface PropertiesProps {
  envType: string;
  selectedAppId: number;
  selectedVersionId: number;
  timestamp: { start: number; end: number };
}

interface PropertiesWithOrder extends PropertyConfigSchemaOutput {
  order: string;
}

export const Properties = (props: PropertiesProps) => {
  const { selectedAppId, selectedVersionId, envType, timestamp } = props;

  const { type } = getAppStorage();

  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [currentProperty, setCurrentProperty] = useState<PropertyConfigSchemaOutput | null>(null);
  const [isDragAndDrop, setIsDragAndDrop] = useState(false);
  const [activeOrder, setActiveOrder] = useState<null | string>();
  const [listOfProperties, setListOfProperties] = useState<PropertyConfigSchemaOutput[]>([]);
  const [listOfNAProperties, setListOfNAProperties] = useState<PropertyConfigSchemaOutput[]>([]);

  const { data: propertylist } = useListPropertiesDefinitions(selectedAppId, { enviroment: type });

  const { data: listPropertiesScores, refetch: refetchScores } =
    useListPropertiesDataApiV1ApplicationVerionsAppVersionIdPropertiesConfigDataGet(selectedVersionId, {
      start_time_epoch: timestamp.start && !Number.isNaN(timestamp?.start) ? timestamp.start : undefined,
      end_time_epoch: timestamp.end && !Number.isNaN(timestamp?.end) ? timestamp.end : undefined
    });

  const {
    data: listProperties,
    refetch: refetchList,
    isLoading
  } = useListPropertiesConfigs({ app_id: selectedAppId, only_in_dashboard: true });

  const { app_latest_update_time } = usePollingIndicator();

  const { getPropertyInfo } = usePropertiesInfo();

  const curListProperties = useMemo(
    () =>
      Array.isArray(listProperties)
        ? envType === EnvType['PENTEST']
          ? listProperties?.filter(el => el.kind === 'garak')
          : listProperties?.filter(el => el.kind !== 'garak')
        : [],
    [listProperties, envType]
  );

  const sensors = useSensors(useSensor(MouseSensor, {}), useSensor(TouchSensor, {}), useSensor(KeyboardSensor, {}));

  const scores = listPropertiesScores && listPropertiesScores[envType];
  const properties: PropertiesWithOrder[] = Array.isArray(listOfProperties)
    ? (listOfProperties?.map((el, index) => ({ ...el, order: `${index}` })) as PropertiesWithOrder[])
    : [];
  const items = useMemo(() => properties?.map(({ order }) => order), [properties]);

  const openAddDialog = () => setIsAddDialogOpen(true);
  const closeAddDialog = () => setIsAddDialogOpen(false);
  const handleDragStart = (event: DragOverEvent) => setActiveOrder(event.active.id ? `${event.active.id}` : undefined);
  const handleEditClick = () => setIsDragAndDrop(true);
  const handleDragCancel = () => setActiveOrder(null);

  const refetchProperties = async () => {
    await refetchList();
    await refetchScores();
  };

  const calculateScore = (id: number) =>
    scores && id && (typeof scores[id] === 'number' ? +Number(scores[id]).toFixed(2) : scores[id]);

  const handleSaveClick = () => {
    setIsDragAndDrop(false);
    const listOfPropertiesIds = listOfProperties.map(el => el.id);
    propertiesConfigsReorderApiV1PropertiesConfigReorderPut(listOfPropertiesIds);
  };

  const handleDragEnd = (event: DragOverEvent) => {
    const { active, over } = event;

    if (active?.id !== over?.id) {
      const sortedProperties = handleChangeOrderOfProperties(properties, active, over);

      setListOfProperties(sortedProperties);
      setActiveOrder(null);
    } else {
      setActiveOrder(null);
    }
  };

  const selectedProperty = useMemo(() => {
    if (!activeOrder) {
      return null;
    }

    const property = properties.find(({ order }) => order === activeOrder);
    return property;
  }, [activeOrder]);

  useEffect(() => {
    refetchScores();
  }, [app_latest_update_time]);

  useEffect(() => {
    if (Array.isArray(curListProperties)) {
      const propToShow = curListProperties.filter(
        el => calculateScore(el.id) !== undefined && calculateScore(el.id) !== null
      );
      const propWhichNa = curListProperties.filter(
        el => calculateScore(el.id) === undefined || calculateScore(el.id) === null
      );

      setListOfProperties(propToShow);
      setListOfNAProperties(propWhichNa);
    }
  }, [curListProperties, listPropertiesScores, envType]);

  return (
    <PropertiesContainer type="card" data-testid="PropertiesContainer">
      <PropertiesHeaderContainer>
        {!currentProperty && (
          <>
            <StyledText type="h2" text={constants.properties.title} />
            {isDragAndDrop ? <DoneOutlined onClick={handleSaveClick} /> : <EditRounded onClick={handleEditClick} />}
          </>
        )}
      </PropertiesHeaderContainer>
      {isLoading ? (
        <LoaderContainer>
          <PropertiesLoader />
        </LoaderContainer>
      ) : currentProperty ? (
        <PropertyDrillDown
          selectedEnv={envType}
          timestamp={timestamp}
          versionId={selectedVersionId}
          score={calculateScore(currentProperty?.id)}
          currentProperty={currentProperty}
          setCurrentProperty={setCurrentProperty}
          propertyInfo={getPropertyInfo(currentProperty?.text_property)}
          listOfProperties={listOfProperties}
        />
      ) : propertylist?.length || curListProperties.length ? (
        <PropertiesInnerContainer data-testid="PropertiesInnerContainer">
          <DndContext
            sensors={sensors}
            onDragEnd={handleDragEnd}
            onDragStart={handleDragStart}
            onDragCancel={handleDragCancel}
            collisionDetection={closestCenter}
            modifiers={[restrictToParentElement]}
          >
            <SortableContext items={items} strategy={rectSortingStrategy}>
              {properties.map(prop => (
                <Property
                  key={prop?.order}
                  score={calculateScore(prop?.id)}
                  refetchProperties={refetchProperties}
                  propertyData={prop}
                  propertyInfo={getPropertyInfo(prop?.text_property)}
                  isDragAndDrop={isDragAndDrop}
                  onClick={() => (isDragAndDrop ? {} : setCurrentProperty(prop))}
                  activeOrder={activeOrder}
                />
              ))}
            </SortableContext>
            <DragOverlay dropAnimation={null}>
              {activeOrder && (
                <StaticDndProperty
                  score={calculateScore(selectedProperty?.id as number)}
                  propertyData={selectedProperty as PropertyConfigSchemaOutput}
                  propertyInfo={getPropertyInfo(selectedProperty?.text_property as string)}
                />
              )}
            </DragOverlay>
          </DndContext>
          {listOfNAProperties.length > 0 && <PropertiesPendingAndNACard listOfNAProperties={listOfNAProperties} />}
          <PropertyContainer type="card" onClick={openAddDialog} data-testid="AddPropertyButton">
            <PropertiesAddCardText type="h3" text={constants.properties.addCardLabel} />
          </PropertyContainer>
        </PropertiesInnerContainer>
      ) : (
        <OverviewNoDataAvailable type="Properties" />
      )}
      <AddOrEditPropertyDialog
        open={isAddDialogOpen}
        closeDialog={closeAddDialog}
        selectedAppId={selectedAppId}
        refetchProperties={refetchProperties}
      />
    </PropertiesContainer>
  );
};
